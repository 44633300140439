import {UAChecker} from "imagelogic-tools/src/device/UAChecker";

(function() {
  if(document.querySelector == undefined) {return;}

  let isTablet: boolean,
    isSMP: boolean;

  isTablet = UAChecker.isTablet();
  isSMP = UAChecker.isSMP();

  let m: HTMLMetaElement,
    h: HTMLHeadElement;

  h = <HTMLHeadElement>document.querySelector('head');
  m = <HTMLMetaElement>document.createElement('meta');
  m.name = 'viewport';

  if(isTablet) {
    //m.content = 'width=device-width,initial-scale=0.6,maximum-scale=1';
  } else {
    m.content = 'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no';
  }
  h.appendChild(m);

  // width fix

})();
